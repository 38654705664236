import React from 'react';

const images = [
  { id: 1, src: '2.gif', alt: 'Ürün Resmi 1' },
  { id: 2, src: '3.jpg', alt: 'Ürün Resmi 2' },
  { id: 4, src: '4.jpg', alt: 'Ürün Resmi 3' },
  { id: 5, src: '5.jpg', alt: 'Ürün Resmi 4' },
  { id: 6, src: '6.gif', alt: 'Ürün Resmi 5' },
  { id: 7, src: '7.jpg', alt: 'Ürün Resmi 6' },
  { id: 8, src: '8.jpg', alt: 'Ürün Resmi 7' },
  { id: 9, src: '9.jpg', alt: 'Ürün Resmi 8' },
  { id: 10, src: '10.jpg', alt: 'Ürün Resmi 9' },
  { id: 11, src: '11.jpg', alt: 'Ürün Resmi 10' },
  { id: 12, src: '12.png', alt: 'Ürün Resmi 11' },
];

function ProductGallery() {
  const handleImageClick = () => {
    const purchaseFormElement = document.getElementById('purchase-form');
    if (purchaseFormElement) {
      purchaseFormElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="product-gallery">
      {images.map(image => (
        <img
          key={image.id}
          src={image.src}
          alt={image.alt}
          className="product-image"
          onClick={handleImageClick}
        />
      ))}
    </div>
  );
}

export default ProductGallery;
