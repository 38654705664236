import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ThankYou = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const value = params.get('value');
    const currency = params.get('currency') || 'TRY';

    if (value) {
      // Facebook Pixel Purchase Event
      if (window.fbq) {
        window.fbq('track', 'Purchase', {
          value: value,
          currency: currency
        });
      }

      // URL parametrelerini temizle
      params.delete('value');
      params.delete('currency');
      navigate({
        pathname: location.pathname,
        search: params.toString()
      }, { replace: true });
    }
  }, [location, navigate]);

  return (
    <div>
      <h1>Teşekkürler!</h1>
      <p>Siparişiniz başarıyla alınmıştır.</p>
    </div>
  );
};

export default ThankYou;
